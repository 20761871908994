.view-v6-home-page-top-col {

  .views-row:not(:last-child) {
    margin-bottom: 30px;
  }

  .views-row {
    .teaser {
      display: none;
    }
  }
}


.home-page-top {
  .view-content .views-row {
    .article-image{
      display: none;
    }
  }

  .view-id-V6_home_page , .attachment-before {
    .view-content .views-row {
      .article-image {
        display: inline;
      }
    }
  }
  .attachment-before {
    margin-bottom: 30px;
  }
  .view-v6-home-page-top-col .views-row .teaser {
    display: block;
  }
  .view-id-v6_home_page_top_col.view-display-id-block_1,
  .view-id-v6_home_page_top_col.view-display-id-block  {

   > .view-content {
      .teaser {
        display: none;
      }
    }
  }


}



.view-taxonomy-term.view-id-taxonomy_term.view-display-id-panel_pane_6 {
  margin-top: 30px;
   .view-content {
     display:grid;
     grid-template-columns: repeat(3,1fr);
     gap:30px;

     .teaser {
       display: none;
     }
   }
}


.view-taxonomy-term.view-id-taxonomy_term.view-display-id-panel_pane_4 {
  background-color: #F7F7F7;
  padding: 30px;
}