$stoewer-font-open:'Open Sans', sans-serif;


$prestiz-family-footer: 'Roboto', sans-serif;
$prestiz-family-title: 'Crimson Pro', serif;
$prestiz-title:$prestiz-family-title;


$prestiz-family-main: 'Noto Serif', serif;
$prestiz-main:$prestiz-family-main;
$prestiz-family-top-menu:'Lato', sans-serif;



$presiz-menu-family:'Lato', sans-serif;

$prestiz-title-family:'Playfair Display', serif;




a,a:visited {
  color: $prestiz-color-gold;
}


h2.pane-title  {
  font-family: $prestiz-title;
  color: #666666;
  font-size: 18px;
}


body {
  font-family: $prestiz-main;
}


//
//.article-text-m {
//  margin-left: 120px;
//  margin-right: 120px;
//}