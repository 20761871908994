#footer {
  background-color: $prestiz-color-navy;
  .menu-name-menu-online {
    margin-top: 0px;
  }
  .menu-name-menu-online ul {
    letter-spacing:normal;
    font-family: $prestiz-family-footer;
    flex-direction: column;
    align-items:start;
    font-size:1.3em ;
    text-transform: none;
    padding-bottom: 0px;
  }
  .menu-name-menu-online {
    a,a:visited {
      color: white;
      font-family: $prestiz-family-footer;
      color: $prestiz-color-gold;
    }
  }

  .menu-name-menu-online ul ul {
   gap:5px;
   font-size:0.8em ;
    font-weight:400 ;
  }

 .footer-label {
   color: $prestiz-color-gold;
   color: rgba(183, 146, 94, 0.8);;
   border-bottom: 1px solid rgba(183, 146, 94, 0.5);
   padding-top: 10px;
   h3 {
     font-size: 0.7em;
     margin-bottom: 4px;
     font-weight: 500;
   }
 }

  .footer-logo {
    padding-top: 15px;
    img {
      filter: invert(55%) sepia(90%) saturate(207%) hue-rotate(356deg) brightness(91%) contrast(88%);
      width: 156px;
      height: auto;

    }
  }

  .view-id-archiwum {
    .field-magazyn-wydanie-okladka {
      img {
        width: 100%;
        height: auto;
      }
    }
  }


  .footer-issue {
    padding-top: 30px;
    margin-bottom: 25px;
  }

  .footer-menu {
    font-family: $prestiz-family-footer;
    font-size: 0.7em;
    font-weight: 300;
    padding-bottom: 15px;
     ul li {
       margin: 0;
       padding: 0;
       text-indent: 0;
       list-style: none;
     }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      gap: 30px;
    }

    img {
      filter: invert(55%) sepia(90%) saturate(207%) hue-rotate(356deg) brightness(91%) contrast(88%);
    }
  }
}

.view-id-patronaty,
.view-id-autor.view-display-id-artykuly ,
.view-taxonomy-term {
  ul.pager {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:30px;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    li {
      margin: 0;
      padding: 0;
      text-indent: 0;
      list-style: none;
    }

    .pager-previous {
      width: 100px;
    }

    .pager-next {
      width: 100px;
    }
  }
}

.view-v6-home-page-top-col.view-id-v6_home_page_top_col.view-display-id-chronicles {
  margin-bottom: 60px;
  .view-header {
    margin-top: 2em;
  }
  .title {
    color: $prestiz-color-gold;
    font-weight: normal;
    font-size: 1.1em;
    margin-top: 0.5em;
  }

  .node--v6-home-teaser {
    a,a:visited {
      color: $prestiz-color-gold;
    }
  }

  //.zobacz-wiecej {
  //  display: flex;
  //  align-items: center;
  //}
}

.full-width {
  //margin-top: 15px;
  //margin-bottom: 15px;
  //position: relative;
  //width: calc(100vw - 10px);
  //margin-left: calc(-50vw + 5px);
  //left: 50%;
  //right: 50%;
  //margin-right: -50vw;

  width: calc(100vw - 8px);
  //width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  .container {
    //padding-left: 0px;
  }


  background-color: $prestiz-color-navy;
}