// Headings
// -----------------------------------------------------------------------------
h1, h2, h3, h4, h5, h6 {

}

// Text
// -----------------------------------------------------------------------------
p {

}

// Links
// -----------------------------------------------------------------------------
a {

  @include hover {

  }
}


a:hover {
  text-decoration: none;
}

// Lists
// -----------------------------------------------------------------------------
ol,
ul,
dl {

}

// Image
// -----------------------------------------------------------------------------
img {

}

figure {

}

figcaption {

}

// Other
// -----------------------------------------------------------------------------
blockquote {

}

strong {

}

small {

}

