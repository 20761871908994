.adslist {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
}



.view-id-galeria.view-display-id-v6_artykul {
   > .view-content {
    margin-top: 60px;
  }
   .views-row  {
     pading-bottom: 30px;
     img {
       max-width: 100%;
       height: auto;
     }
   }
}







.article-galery-more-foto {

}

.tytul-artykul {
}

@include media-breakpoint-up(lg) {
  .tytul-artykul {
    padding-left: 100px;
    padding-right: 100px;

  }
}


.tytul-artykul {
  h1 {
    font-weight: normal;
    line-height: 1.2em;
    font-size: 1.75em;
  }
  h1 .field-tytul {
    font-family: $prestiz-family-title;
    font-size: 2.0em;
    line-height: 1.0em;
    p {
      margin-bottom: 0;
    }
  }
  margin-bottom: 40px;
}


.autor-header {
  display: flex;
  align-items: center;
  justify-content: center;


  .autor--v6-teaser {
    flex-direction:column;
  }

}





.artykul-header  {
  .field-category-online {
    font-family: $presiz-menu-family;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;
    letter-spacing: 0.23em;
    color: $prestiz-color-gold;
    margin-bottom: 1.0em;
  }
  .magazine-category {
    color: $prestiz-color-gold;

    margin-top: 0.7em;

    font-size: 0.8em;
    .field-artykul-magazyn-kategoria:before {
      //padding-left: 1em;
      content: ", dział: ";
    }

    .field-artykul-magazyn-kategoria2:before {
      content: " / ";
    }
  }

}
.field--artykul-magazyn-zd-opis {
  margin: 60px 15px 30px 15px;
  font-family: $prestiz-family-title;
  font-size: 1.5em;
  font-weight: 800;
  line-height: 1.4em;
}
@include media-breakpoint-up(lg) {

  .field--artykul-magazyn-zd-opis {
    margin: 60px 4em 30px 4em;
    font-family: $prestiz-family-title;
    font-size: 2.0em;
    font-weight: 800;
    line-height: 1.4em;
  }
}

.tresc {
  margin: 3em 15px 3em 15px;
  font-size: 1.0em;
  line-height: 1.6em;
}
@include media-breakpoint-up(md) {
  .tresc {
    margin: 3em 10em 4em 10em;
    font-size: 1.2em;
    line-height: 1.6em;
  }

}

@include media-breakpoint-up(xxl) {
  .tresc {
    margin: 3em 10em 4em 10em;
    font-size: 1.4em;
    line-height: 1.6em;
  }

}




.article-main-foto {
  margin-top: 3em;
  img {
    max-width: 100%;
    height: auto;
  }
}


.article-main-foto-autor {
  //transform: rotate(-90deg);
  font-size: 0.75em;
  letter-spacing: 0.06em;
  line-height: 1.2em;
  writing-mode: vertical-rl;
  white-space: nowrap;
  text-align: left;
  font-weight: bold;
  font-family: $presiz-menu-family;


}

.article-main-foto-autor:before {
    content: "foto:";
    font-weight: normal;
}

.article-main-foto-desc {
  margin-top: 3px;
  font-family: $presiz-menu-family;
  font-size: 0.8em;
  letter-spacing: 0.06em;
  line-height: 1.2em;
  text-align: right;
  color: $prestiz-color-black2;
  color: rgba(0,0,0,0.70);
}


.article-footer {
  .space-line {
    border-top: 1px solid $prestiz-color-gold;
    padding-bottom: 90px;
    width: 100%;
  }
  padding-top: 30px;
}


.magazyn-autor-bio .autor-fota-opis{
  background-color: fade-out($prestiz-color-gold3 , 0.5);
  background-color: lighten($prestiz-color-gold,40%);
  padding: 60px 60px 45px 60px;
  margin-bottom: 30px;
  .opis  {
    font-size:1em;
    line-height: 1.9em;
    color: rgba(0, 0, 0, 0.8);
  }

  .zdjecie {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 130px;
      height: auto;
      margin-bottom: 15px;
    }
  }
  .nazwisko {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      display: block;
      font-weight: 700;
      word-spacing: 100vw;
      text-align: center;
      font-family: $presiz-menu-family;
      font-size: 1.25em;
      margin-bottom: 20px;
    }
  }
}




.magazyn-wydanie-teaser-0 {


   img {
     width: 100%;
     height: auto;
   }

   .numer {
     font-family:$presiz-menu-family;
     display: flex;
     justify-content: space-between;
     color: fade-out($prestiz-color-black,0.3);
     strong {
       display: flex;
       flex-direction: column;
     }
      strong:before {
        content: "wydanie";
        font-weight: 400;
        color: fade-out($prestiz-color-black,0.4);
        font-size: 0.8em;
        line-height: 0.8em;
     }



   }

  .field-magazyn-wydanie-data {
    span {
      display: flex;
      flex-direction: column;
      font-weight: 700;
    }
    span:before {
      content: "data";
      font-weight: 400;
      color: fade-out($prestiz-color-black,0.4);
      font-size: 0.8em;
      line-height: 0.8em;
    }

  }

  //.opis {
  //  display: flex;
  //  justify-content: space-between;
  //}

  .pobierz {
    display: none;
  }
  .magazyn-typ {
    color: fade-out($prestiz-color-black,0.3);
    text-transform: capitalize;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 8px;
    letter-spacing: 0.1em;
  }

}


.issue-short {
  color: $prestiz-color-gold;
  font-family: $prestiz-family-top-menu;
  justify-content: center;
  margin-bottom: 45px;
  a, a:visited {
    color: $prestiz-color-gold;
  }
  display: flex;
  .magazyn-typ {
    text-transform: capitalize;
  }



}


.node-type-autor {
  .autor-fota-opis {
    padding: 15px;
    background-color: lighten($prestiz-color-gold,40%);
    .zdjecie {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nazwisko {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0px;
      h2 {
        text-align: center;
      }
    }
    margin-bottom: 30px;
    .opis {
      text-align: justify;
    }
  }
  .view-autor.view-id-autor .view-header{
      font-size:0.8em;
      color: $prestiz-color-grey2;
    font-family: $prestiz-family-top-menu;
  }
}


  .paragraf-przeglad {
    .field-para-przeglad-img {

        text-align: center ;

    }
  }
