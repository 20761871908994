.paragraf-przeglad {
  #galeria {
    img {
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.paragraphs-items-field-paragraf {
  .field-paragraf {
    margin-bottom: 60px;
  }
}

.paragraphs-item-ludzie {

  img {
    width: 100%;
    height: auto;
  }
  .opis .field-para-ludzie-teaser {
    font-size: 1.0em;
    line-height: 1.6em;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@include media-breakpoint-up(lg) {
  .paragraphs-item-ludzie {
    .opis .field-para-ludzie-teaser{
      margin-right: 0;
      margin-left: 0;
      font-size: 1.5em;
      line-height: 1.6em;
    }
  }
}


.field-para-ludzie-name {
  //font-family: $presiz-menu-family;
  font-weight: 600;
  //color: $prestiz-color-gold;
  margin-bottom: 1.0em;
  font-size: 1.2em;
}