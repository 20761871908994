.autor--v6-teaser {
  font-family: $presiz-menu-family;
  font-weight: bold;
  font-size: 0.75em;
  .autor-picture {
    margin-right: 5px;
  }
  .autor-empty {
    width: 40px;
    height: 40px;
    background-color:$prestiz-color-navy;
    border-radius: 45px;

  }
  .field-autor-zdjecie {
    width: 40px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

a,a:visited {
  .autor--v6-teaser {
    color:$prestiz-color-black;
  }
}


.node--v6-home-teaser {
  .title {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.2em;
    a,a:visited {
      color: $prestiz-color-navy;
    }
  }
  .category{
    font-family: $presiz-menu-family;
    font-weight: bold;
    font-size: 0.63em;
    color: $prestiz-color-gold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
  }
  .teaser {
    margin-top: 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    font-family:$prestiz-family-main;
    a,a:visited {
      color: $prestiz-color-navy;
    }
  }

  .article-image  img {
    max-width: 100%;
    height: auto;
  }

  .autor {
    margin-top: 15px;
  }

}




.view-id-v6_home_page_top_col.view-display-id-block_4 {
  .views-row {
    .teaser {
      display: block;
    }

    .field-autor-zdjecie {
      width: 80px;
      margin-bottom: 15px;
      margin-top: 30px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}




.prestiz-3-grid {
  .autor, .teaser, .category {
    display: none;
  }

  .views-row {
    margin-bottom: 8px;
  }
  .views-row:not(:first-child) {
      .article-image {
        display: none;
      }

      .title {
        font-weight: normal;
        font-size:1.0em ;
      }

  }

  .views-row:first-child {
    .title {
      margin-top:15px;
    }
  }


  .views-row:not(:last-child)  {
    border-bottom:solid 1px rgba(0, 0, 0, 0.08);
    padding-bottom:15px ;
  }

  .node--v6-home-teaser {
    display: flex;
    flex-direction: column;
    .title {
      order: 2;
    }

    .article-image {
      order: 1;
    }
  }


  .view-header {
    text-align: center;
  }

}

