.view-id-v6_home_page_top_col.view-display-id-block_5 {

  .view-content {
    //display: grid;
    //grid-template-columns: repeat(4,1fr);
    //grid-column-gap: $grid-gutter-width;
  }


  .node--v6-home-teaser {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: auto;
    grid-template-areas: "images category category"   "images title title"  "images autor autor";
    grid-column-gap: $grid-gutter-width / 2;
  }


  .article-image {
    grid-area: images;
    display: block !important;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .category {
    grid-area: category;
    font-family: $presiz-menu-family;
    font-weight: bold;
    font-size: 0.63em;
    color: $prestiz-color-gold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 0.63em;
  }

  .title {
    grid-area: title;
    font-size: 1.0em;
    font-weight: bold;
    line-height: 1.1em;


    a, a:visited {
      color: $prestiz-color-navy;
    }
  }


  .autor {
    grid-area: autor;
    .autor-picture {
      display: none;
    }
  }

}

.view-id-V6_home_page_3city {
  background-color: #F7F7F7;

    margin-left: -30px;
    margin-right: -30px;
    padding:30px;
   .article-image {
    display:block !important;
  }

  >.view-content {
    //display: grid;
    //
    //grid-template-columns: repeat(3,1fr);
    //grid-template-rows: auto;
    //grid-auto-rows: min-content;
    //grid-template-areas: "it1 it2 it2" ;
    //
    //grid-column-gap: 30px;
  }

  .left-column {
    grid-area: it1;
    .node--v6-home-teaser {
      display: flex;
      flex-direction: column;


      .article-image {
        order: 1;
        margin-bottom: 30px;
      }
      .category {
        order: 2;
        text-align: center;
      }
      .title {
        order: 3;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        font-size: 1.8em;
      }

      .teaser {
        order: 4;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
      }

      .autor {
        order: 5;
        .autor--v6-teaser {
          display: flex;
          flex-direction: column;
        }


      }

    }

  }

  .right-column {
    //grid-area: it2;
    //display:grid;
    //grid-auto-rows: min-content;
    //grid-template-columns: repeat(2,1fr);
    //gap: 30px;
    //grid-column-gap: 30px;
  }

  .right-column .views-row {
    .node--v6-home-teaser {
      display:grid;
      grid-template-columns: repeat(3,1fr);
      grid-template-areas: "images category category"  "images title title" "images teaser teaser"  "images autor autor";
      gap:0;
      grid-column-gap: $grid-gutter-width / 2;

      .article-image {
        grid-area: images;
        display: block !important;

        img {
          max-width: 100%;
          height: auto;
        }
      }
      .category {
        grid-area: category;
        font-family: $presiz-menu-family;
        font-weight: bold;
        font-size: 0.63em;
        color: $prestiz-color-gold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-bottom: 0.63em;
      }

      .title {
        grid-area: title;
        font-size: 1.0em;
        font-weight: bold;
        line-height: 1.1em;


        a, a:visited {
          color: $prestiz-color-navy;
        }
      }

      .teaser  {
        //display: none;
        grid-area: teaser;
        font-size: 0.9em;
        margin-top: 0.5em;
      }

      .autor {
        grid-area: autor;
        margin-top: 0;
        .autor-picture {
          display: none;
        }
      }
    }
  }
}

.pane-v6-home-page-nie-przegap {
  background-color: #F7F7F7;
  margin-left: -$grid-gutter-width;
  margin-right: -$grid-gutter-width;
  padding:$grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  margin-top: $grid-gutter-width;

  .view-content {
  //  display: grid;
  //  grid-template-columns: repeat(3,1fr);
  //  grid-template-rows: auto;
  //  grid-auto-rows: min-content;
  //  grid-column-gap: $grid-gutter-width;
  }
}


///////////
.prestiz-3-grid {
  margin-top: 60px;



  //grid-template-columns: repeat(4,1fr);
  //grid-template-areas:"header1 header2 header3 header4" "content1 content2 content3  content4";

  //grid-template-columns: repeat(2,1fr);
  //grid-template-areas:"header1 header2" "content1 content2" "header3 header4" "content3  content4";

  //display:grid;
  //gap:30px;
  //grid-row-gap: 0;

  .prestiz-3-grid-col {


  }
  .view-header {
    display: flex;
    flex-direction:column;
    h3 {
      margin-top: auto;
    }

  }
  .view-header:nth-child(1) {
    grid-area: header1;
  }
  .view-header:nth-child(3) {
    grid-area: header2;
  }

  .view-header:nth-child(5) {
    grid-area: header3;
  }

  .view-header:nth-child(7) {
    grid-area: header4;
  }


  .view-content :nth-child(2) {
    grid-area: content1;
  }
  .view-content :nth-child(4) {
    grid-area: content2;
  }
  .view-content :nth-child(6) {
    grid-area: content3;
  }
  .view-content :nth-child(8) {
    grid-area: content4;
  }

  .view-content {
    margin-bottom: 60px;
  }
}

///////////////////////////////////

.view-id-v6_home_page_top_col.view-display-id-block_4 {
  .view-content {
    //grid-template-columns: repeat(4, 1fr);
    //grid-margin: 30px;
    //gap: 30px;
    //display: grid;
  }
  .view-content .views-row:nth-child(n+2) {
    .article-image{
      display: block;
    }
  }

  .node--v6-home-teaser {
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
  }
  .views-row:last-child {
    margin-bottom: 30px;
  }

  .views-row {

    display: flex;

  }


  .article-image {
    display: none;
  }


  .category {
    order: 1;
    text-align: center;

  }
  .title {
    order: 2;
    overflow: hidden;
    text-align: center;
  }
  .teaser {
    order: 3;
    display: block;
    text-align: center;
  }

  .autor {
    order: 4;
    margin-top:auto;
    .autor--v6-teaser {
      display: flex;
      flex-direction: column;
    }
  }



}

.view-id-v6_home_page_top_col.view-display-id-block_4 {
  .node--v6-home-teaser .article-image {
    display: none !important;
  }
}



////////////

@media (min-width: 768px) {
  .prestiz-3-grid {
    display:grid;
    gap:30px;
    grid-row-gap: 0;
    grid-template-columns: repeat(2,1fr);
    grid-template-areas:"header1 header2" "content1 content2" "header3 header4" "content3  content4";

  }

  .view-id-v6_home_page_top_col.view-display-id-block_4 {
    .view-content {
      grid-template-columns: repeat(2, 1fr);
      grid-margin: 30px;
      gap: 30px;
      display: grid;
    }
  }

  .pane-v6-home-page-nie-przegap {
    background-color: #F7F7F7;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
    padding:$grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    margin-top: $grid-gutter-width;

    .view-content {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-auto-rows: min-content;
      grid-column-gap: $grid-gutter-width;
    }
  }


  .view-id-v6_home_page_top_col.view-display-id-block_3 {
    .view-content {
      grid-template-columns: repeat(2, 1fr);
      grid-margin: 30px;
      gap: 30px;
      display: grid;
    }
  }

}


@media (min-width: 1200px) {
  .prestiz-3-grid {
    display:grid;
    gap:30px;
    grid-row-gap: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:"header1 header2 header3 header4" "content1 content2 content3  content4";
  }

  .view-id-v6_home_page_top_col.view-display-id-block_4 {
    .view-content {
      grid-template-columns: repeat(4, 1fr);
      grid-margin: 30px;
      gap: 30px;
      display: grid;
    }
  }

  .pane-v6-home-page-nie-przegap {
    background-color: #F7F7F7;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
    padding:$grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    margin-top: $grid-gutter-width;

    .view-content {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-template-rows: auto;
      grid-auto-rows: min-content;
      grid-column-gap: $grid-gutter-width;
    }
  }

  .view-id-v6_home_page_top_col.view-display-id-block_3 {
    .view-content {
      grid-template-columns: repeat(3, 1fr);
      grid-margin: 30px;
      gap: 30px;
      display: grid;
    }
  }

  .view-id-v6_home_page_top_col.view-display-id-block_2 {
    .view-content {
      grid-template-columns: repeat(3, 1fr);
      grid-margin: 30px;
      gap: 30px;
      display: grid;
    }
  }

  .view-id-V6_home_page_3city {

    > .view-content {
      display: grid;

      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-auto-rows: min-content;
      grid-template-areas: "it1 it2 it2";

      grid-column-gap: 30px;
    }
  }

  .view-id-V6_home_page_3city .right-column {
    grid-area: it2;
    display: grid;
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    grid-column-gap: 30px;
  }

  .view-id-v6_home_page_top_col.view-display-id-block_5 .view-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
  }

}

.page-node-34091,
.page-node-34092 {

  .node-strona {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    .content {
      //flex-grow : 1;
    }
    h2 {
   text-align: center;
      font-size: 3em;
    }

    p {
      text-align: center;
    }
  }


}