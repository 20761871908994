

.header-top {
  padding-top: 15px;
}


.header-top-left {

  align-items: center;
  padding-right:30px;
  gap: 30px;
}


.header-top-left-menu  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;


  a, a:visited {
    font-family: $prestiz-family-top-menu;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.7);
  }
}

.header-top-mobile {
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  .logo-mobile img {
    height: 32px;
    width: auto;
  }

  .header-hamburger-mobile-open img{
    height: 30px;
    width: auto;
  }
}




.header-top-right {
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 24px;
}


.menu-name-menu-online {
  margin-top: 15px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);


  font-family: $presiz-menu-family;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.2em;

  a, a:visited {
    font-family: $prestiz-family-top-menu;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 1);
  }

  ul,
  ul li,
  ul ul li {
    margin:0;
    padding: 0;
    text-indent: 0;
    list-style: none;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    gap: 20px;
  }


}











.view-id-v6_home_page_top_col.view-display-id-block_2 {
 .view-content {
   //grid-template-columns: repeat(3, 1fr);
   //grid-margin: 30px;
   //gap: 30px;
   //display: grid;
   width: 100%;
 }

  .views-row {

    display: flex;

  }



  .view-content .views-row:nth-child(n+2) {
    .article-image{
      display: block;
    }
  }

  .node--v6-home-teaser {
    flex-direction: column;
    display: flex;


    .article-image {
      order: 1;
    }
    .category {
      order: 2;
    }
    .title {
      order: 3;
      overflow: hidden;
    }
    .autor {
      order: 5;
      //margin-top:auto;

    }


  }


  .views-row:last-child {
    margin-bottom: 30px;
  }
  .autor-picture {
    display: none;
  }

}


.view-header {
  h3 {
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 10px;
    color: $prestiz-color-gold;
  }
  margin-bottom: 30px;
}



.view-id-v6_home_page_top_col.view-display-id-block_3 {
  .view-content {
    //grid-template-columns: repeat(3, 1fr);
    //grid-margin: 30px;
    //gap: 30px;
    //display: grid;
  }
  .view-content .views-row:nth-child(n+2) {
    .article-image{
      display: block;
    }
  }

  .node--v6-home-teaser {
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
  }
  .views-row:last-child {
    margin-bottom: 30px;
  }

  .title {
    margin-bottom: auto;
  }
  .views-row {

    display: flex;

  }
}






///////////


.row-full_{
  width: 100vw;
  width: calc(100vw - 8.1px);
  position: relative;
  margin-left: -50vw;
  //height: 100px;
  //margin-top: 100px;
  left: 50%;
}

//////////////////menu tax --------------///////////


.pane-menu-block-16 {
  margin-top: -15px;
  .menu-block-16 {
    margin-top: 0px;
    border-top: none;

    > .menu {
      display: block;
      padding-top: 0px;

      > li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 0px;
        gap: 20px;

        a {
          display: block;
          font-weight: 500;
        }

        a, a:visited {
          //color: $prestiz-color-gold;
        }

        a.active, a.active:visited {
          color: $prestiz-color-gold;
        }
      }
    }

    ul {
      padding:0 ;
      padding-bottom: 5px;
    }
    ul ul {
      padding:0;
    }

  }


}



///////////////

.pane-taxonomy-term {
  margin-top:30px;
  margin-bottom: 30px;

   h1.field-category-online{
    text-align: center;
    font-size: 2rem;;
  }
  h1.pane-title{
    text-align: center;
    font-size: 2rem;;
  }


}

.panel-pane.pane-custom.pane-1 {
  //margin-top:30px;
  //margin-bottom: 30px;
  h1.pane-title{
    text-align: center;
    font-size: 2rem;;
  }
}



#main .view-display-id-panel_pane_4 {

  .view-content .views-row .article-image {
    display: inline;
  }

  .node--v6-home-main-teaser {
    display: flex;
    flex-direction: column;

    .article-image {
      order: 1;
      margin-bottom: 30px;
    }

    .category {
      order: 2;
      text-align: left;
    }

    .title {
      order: 3;
      padding-left: 0px;
      text-align: left;
      font-size: 1.8em;
    }

    .teaser {
      order: 4;
      padding-left: 0px;
      padding-right: 0px;
      text-align: left;
    }

    .autor {
      order: 5;
      .autor--v6-teaser {
        display: flex;
        flex-direction: row;
      }

    }
  }
}


#main .view-display-id-panel_pane_5 {
  .view-content .views-row-1 .article-image {
    display: inline;
  }

  .view-content .views-row-3,
  .view-content .views-row-2 {
     .teaser {
       display: none;
     }
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 15px;
  }

  .node--v6-home-teaser {
    display: flex;
    flex-direction: column;
    .category {
      order: 2;
      text-align: left;
    }

    .title {
      order: 3;
      text-align: left;
      font-size: 1.8em;
    }

    .teaser {
      order: 4;
      text-align: left;
    }

    .autor {
      order: 5;
      .autor--v6-teaser {
        display: flex;
        flex-direction: row;
      }

    }


  }
}



//////////////


.view-id-autor.view-display-id-artykuly,
#main .view-display-id-panel_pane_3 {
  .node--v6-home-teaser {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content min-content min-content;
    grid-template-areas:
        "images category category"
        "images title title"
        "images teaser teaser";
    grid-column-gap: 30px;


    .article-image {
      grid-area: images;
    }

    .category {
      grid-area: category;
      margin-bottom: 5px;
    }

    .title {
      grid-area: title;
      padding-left: 0px;
      text-align: left;
      font-size: 1.8em;
    }

    .teaser {
      grid-area: teaser;
      padding-left: 0px;
      padding-right: 0px;
      text-align: left;
    }

    .autor {
      display: none;
      .autor--v6-teaser {
        display: flex;
        flex-direction: row;
      }

    }
  }

  .views-row {
    margin-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 30px;
  }
}


.view-id-patronaty  {
  .node--v6-home-teaser {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content min-content min-content min-content;
    grid-template-areas:
        "images category category"
        "images title title"
        "images  miejsce-data  miejsce-data"
        "images teaser teaser";
    grid-column-gap: 30px;


    .article-image {
      grid-area: images;
    }

    .category {
      grid-area: category;
      margin-bottom: 5px;
    }

    .title {
      grid-area: title;
      padding-left: 0px;
      text-align: left;
      font-size: 1.8em;
    }

    .teaser {
      grid-area: teaser;
      padding-left: 0px;
      padding-right: 0px;
      text-align: left;
    }

    .miejsce-data {
      grid-area: miejsce-data;
      padding-left: 0px;
      padding-right: 0px;
      text-align: left;
    }


    .autor {
      display: none;
      .autor--v6-teaser {
        display: flex;
        flex-direction: row;
      }

    }
  }

  .views-row {
    margin-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 30px;
  }
}




[class*="pane-simpleads-ad-groups"] {
  font-family: $prestiz-family-top-menu;
  font-size: 0.6em;
  color: rgba(0, 0, 0, 0.3);
  margin-top:-14.4px
}


///////////
.hamburger-category {
  font-size: 1.4em;
  .menu-name-menu-online {
    border-width: 0px;
    ul  {
      flex-direction: column;
      align-items:normal;
      border: none;
      gap: 5px;
      > li {
        //border-bottom: 1px solid red;
      }
    }

     > ul.menu {
      //border-top: 1px solid rgba(0, 0, 0, 0.08);
      //border-width: 1px 0px 0px 0px;
      //border-color: rgba(0, 0, 0, 0.08);

    }
    > ul > .first > a  {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding-bottom: 5px;
      display: block;
    }

    ul ul {
      padding-top: 10px;
    }
  .leaf {
    line-height: 1.0em;
    letter-spacing: 0.15em ;
  }

  }
}