.node--v6-home-main-teaser {

  display: flex;
  flex-direction: column;


  .article-image {
    order: 1;
    margin-bottom: 30px;
  }
  .category {
    order: 2;
    text-align: center;
  }
  .title {
    order: 3;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    font-size: 1.8em;
  }

  .teaser {
    order: 4;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }

  .autor {
    order: 5;
    .autor--v6-teaser {
      display: flex;
      flex-direction: column;
    }


  }

}