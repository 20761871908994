.header-hamburger-off {
  filter: invert(63%) sepia(51%) saturate(335%) hue-rotate(356deg) brightness(96%) contrast(89%);
}

.hamburger-content {
  height: 100%;
  width: 100%;
  position: fixed;
  top: -100%;
  left: 0;
  //background-color: #CC3300;
  //background-color: #eeeeee;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 30px;
  z-index: 220;

  .footer-label {
    //color: lighten(black, 10%);
    color:red;
  }
}



.hamburger-content {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li.leaf  {
    list-style-image:none;
    list-style-type:none;
    margin: 0;
    margin-bottom: 7px;
  }


  h3 {
    font-family: $prestiz-title-family;
    font-weight: 500;
    font-size: 0.8em;
    margin-bottom: 20px;
    color: lighten(black, 80%);
  }



  li {
    font-family:$presiz-menu-family;
    text-transform: uppercase;
    font-size: 0.8em;

    a,a:visited {
      color: $prestiz-color-black2;
      text-decoration: none;
      padding-bottom: 12px;
      font-size: 0.9em;
    }
  }
}

.hamburger-second-menu {
  a,a:visited {
    color: $prestiz-color-gold !important;
    font-size: 0.8em;
    letter-spacing: 1px;
  }
  li.leaf {
    margin-bottom: 2px;
  }
}