//.easy_social-widget {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}


.wiadomosci-full {
  .article-main-foto {
    align-items: center;
    justify-content: center;
  }
}