.node--v6-teaser-small {

  grid-template-columns: repeat( 3, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "images category category"   "images title title"  "images data data";

  grid-column-gap: 15px;
  display: grid;

  .article-image {
    grid-area: images;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .category {
    grid-area: category;
    font-family: $presiz-menu-family;
    font-weight: bold;
    font-size: 0.63em;
    color: $prestiz-color-gold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 0.63em;
  }

  .title {
    grid-area: title;
    font-size: 1.0em;
    font-weight: bold;
    line-height: 1.1em;


    a, a:visited {
      color: $prestiz-color-navy;
    }
  }

  .date {
    grid-area: data;
    font-family: $presiz-menu-family;
    font-weight: normal;
    font-size: 0.63em;
    margin-top: 0.5em;
  }

}

.view-id-v6_home_page_patronite.view-display-id-block {

  display: flex;
  flex-direction: column;
  flex: 1;

  .views-row:not(:last-child) {
    margin-bottom: 30px;
  }

  .view-header  {
    margin-bottom: 0;
    h3 {
      margin: 0;
    }
  }
}


.pane-views.pane-v6-home-page-patronite {
  flex: 1;
  display: flex;
  flex-direction: column;
  .pane-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .view-content {
    border-left:2px solid rgba(0, 0, 0, 0.08);
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top:30px;
  }


}

.col-12.col-lg-3.col-xl-3 {
  display: flex;
  flex-direction: column;
}