body.node-type-kronika-prestizowa, body.page-kronika-prestizowa {
  color: $prestiz-color-white;
  //background-color: fade-out($prestiz-color-black, 0.5);
  background-color: $prestiz-color-black1;
  background-color: $prestiz-color-navy;


  .node--v6-home-teaser {
    .title, .teaser {
      a, a:visited {
        color: $prestiz-color-white;
      }
    }
  }

  .header-top h1 img {

    filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(114%) contrast(101%);

  }
  .header-top .header-hamburger-open img {
    filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(114%) contrast(101%);
  }
  .header-top-right {
    filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(114%) contrast(101%);
  }
  .hamburger-category {
     .menu-name-menu-online {
      a, a:visited {
        color: $prestiz-color-black;
      }
    }
  }

  #footer {
    background-color: $prestiz-color-navy;
  }
  .tresc {
     color: $prestiz-color-white;
     h2 {
       text-align: center;
       padding-left: 20%;
       padding-right: 20%;
       font-size: 1.6em;
       margin-bottom: 90px;
     }

    .field-body {
      text-align: center;
      padding-left: 7%;
      padding-right: 7%;
      font-size: 1.3em;
      line-height: 1.5em;
    }
   }


  .menu-name-menu-online {
    border-color: $prestiz-color-white;
    a, a:visited {
      color: $prestiz-color-white;
    }
  }


  .header-top-left-menu {
    a, a:visited {
      color: $prestiz-color-white;
    }
  }

  .f-tax-name {
    text-align: center;
    font-family: $prestiz-family-top-menu;
    text-transform: uppercase;
    letter-spacing: .23em;
    font-size: 0.8em;
  }

  .field-kronika-autor {
    display: flex;
    justify-content: center;
    //margin-bottom: 15px;

    .autor--v6-teaser {
      flex-direction: column;
    }

  }



  .artykulmainfoto {
    color:$prestiz-color-white;
    .article-main-foto-desc {
      margin-top:30px;
      color:fade-out($prestiz-color-white, 0.3);
      font-family: $prestiz-family-main;
      text-align: center;
      padding-left: 15%;
      padding-right: 15%;
      font-weight: 500;
      font-size: 1.1em;
      line-height: 1.3em;


    }
  }


  .magazyn-wydanie-teaser-0 {
    width: 280px;
    margin-top: 60px;
    .field-magazyn-typ, .numer {
      color: fade-out($prestiz-color-white, 0.3);
      ::before {
        color: fade-out($prestiz-color-white, 0.4);
      }
    }



  }
  .issue-short {

  }


  .article-footer {
    margin-bottom: 90px;
  }
  .issue-short {
    color: $prestiz-color-white;
    font-size: 0.6em;
    line-height: 0.8em;
    margin-top: 5px;
    a, a:visited {
      color: $prestiz-color-white;
    }
  }

}


body.node-type-kronika-prestizowa {
  .artykulmainfoto {
.article-main-foto {
  margin-bottom: 90px;
}
  }

}


.view-galeria.view-id-galeria.view-display-id-page {


  .galerymainfoto .galerymainfoto-ramka img {
    width: 100%;
    height: auto;
  }

  a,a:visited {
    color: $prestiz-color-black;
  }

  .pager-ramka {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    .pager {

      list-style: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      font-size: 0.7em;
    }
    a,a:visited {
      color: $prestiz-color-grey3;
    }
    .pager-next ,
    .pager-previous {
      a,a:visited {
        border: 1px solid $prestiz-color-grey;
        padding: 3px 15px 6px 15px;


      }

    }

    .pager-current {

      margin-left: 10px;
      margin-right: 10px;
      color: $prestiz-color-grey3;
      font-weight: 400;

      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding-right: 3px;
      }
    }
  }

  .galeria-tytul {
    span {
      display: block;
      text-transform: uppercase;
      color:$prestiz-color-grey3;
      font-size: 0.5em;
      font-family: $presiz-menu-family;
      margin-bottom: 30px;
    }


    a:first-child {
      //  display: block;
      font-family: $prestiz-title-family;
    }
    a:last-child {
      font-size: 0.6em;
      color: $prestiz-color-gold3;
    }
  }

  .teaser {
    margin-top: 30px;
  }

  #galeria-blok {
    .views-row {
      margin-bottom: 30px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .galerymainfoto-autor {
    margin-bottom: 15px;
    font-size: 0.8em;
  }


  .galerymainfoto-opis {

  }
  .galerymainfoto-opis {
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
   .galerymainfoto-opis {
     padding-left: 100px;
     padding-right: 100px;
   }
  }
}
