.wydarzenie-full {


  .zdjecie {
    img {
      width: 100%;
      height: auto;
    }
  }

  .body {
    margin-top: 60px;
  }


  .header {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-direction:column;
    //height: 100%;
    .patronat {
      margin-bottom: 60px;
    }
    .miejsce-data {
      margin-top: 30px;
      color: $prestiz-color-gold;
      font-family: $prestiz-family-top-menu;
    }
  }



}


.node-type-magazyn-wydanie {

  .view-id-magazyn.view-display-id-lista_kat {
    .view-content div:not(:last-child) .node--v6-home-teaser {
    //.views-row:not(:last-child) {
      margin-bottom: 30px;
    }

    .maping-true-full {
      //background-color: $prestiz-color-gold;
      border:2px solid $prestiz-color-gold;
      padding: 10px;

      .online-maping-row {
        display: flex;

        align-items: baseline;
        .category-online {
          color:$prestiz-color-gold;
          padding-left: 5px;
        }
        .date-online {
          color: $prestiz-color-grey2;
          font-weight:300;
          font-size: 0.6em;

        }
      }
    }
  }

  .lista-artykulow {

      .views-row:not(:last-child) {
        margin-bottom: 30px;
      }

    .category {
      display: none;
    }


    }




    .node--v6-home-teaser {
      display:grid;
      grid-template-columns: repeat(3,1fr);
      grid-template-areas:  "images title title" "images teaser teaser"  "images autor autor";
      gap:0;
      grid-column-gap: $grid-gutter-width / 2;

      .article-image {
        grid-area: images;
        display: block !important;

        img {
          max-width: 100%;
          height: auto;
        }
      }
      .category {
        grid-area: category;
        font-family: $presiz-menu-family;
        font-weight: bold;
        font-size: 0.63em;
        color: $prestiz-color-gold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-bottom: 0.63em;
        display: none;
        display: none;
      }

      .title {
        grid-area: title;
        font-size: 1.0em;
        font-weight: bold;
        line-height: 1.1em;


        a, a:visited {
          color: $prestiz-color-navy;
        }
      }

      .teaser  {
        //display: none;
        grid-area: teaser;
        font-size: 0.9em;
        margin-top: 0.5em;
      }

      .autor {
        grid-area: autor;
        margin-top: 0;
        .autor-picture {
          display: none;
        }
      }
    }

  .view-id-kroniki.view-display-id-archiwum_ {
    .view-content {
      display:grid;
      grid-template-columns: repeat(2,1fr);
      grid-template-areas:  "views-row views-row " "views-row views-row"  ;
      gap:0;
      grid-column-gap: $grid-gutter-width;
      .views-row {
        margin-bottom: 15px;
      }
    }
  }

}